<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos del operador" ref="pageForm" :items="items">
    </PageHeader>
    <ShowDetails :item="dataDetails" ref="showDetails" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <b-form-input v-model="filterData.fromDate" type="date" class="form-control-sm"></b-form-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <b-form-input v-model="filterData.toDate" type="date" class="form-control-sm"></b-form-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Jugador:
                  <select v-model="filterData.playerId" name="playerId" class="form-select form-select-sm m-2"
                    placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="(item, index) in players" :key="index" :value="item._id">
                      {{ item.username }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Operador:
                  <select v-model="filterData.operatorId" name="client" class="form-select form-select-sm m-2"
                    placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="(item, index) in operators" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Ruleta:
                  <select v-model="filterData.rouletteId" name="client" class="form-select form-select-sm m-2"
                    placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="(item, index) in roulettes" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <downloadexcel class="btn btn-secondary" :fetch="exportData" :fields="json_fields" :name="fileName">
              Descargar Excel
            </downloadexcel>
            <button type="button" class="btn btn-primary m-2 w-md" @click="filtrar()">
              Filtar
            </button>
            <button type="button" class="btn btn-secondary m-2 w-md" @click="cancelFilter()">
              Cancelar
            </button>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;{{
                      $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <b-form-input v-model="filter" type="search" placeholder="Buscar..."
                      class="form-control form-control-sm ms-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table :items="tableData" :fields="fields" responsive="sm" :per-page="perPage"
                  :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                  :filter-included-fields="filterOn" @filtered="onFiltered">
                  <template #cell(createdAt)="data">
                    {{
                      moment.utc(data.item.createdAt).format("DD-MM-YYYY HH:mm:ss")
                    }}
                  </template>
                  <template #cell(details)="data">
                    <div>
                      <b-button variant="outline-primary" @click="openDetails(data.item)">{{ $t('helpers.look')
                      }}</b-button>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import downloadexcel from "vue-json-excel";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import ShowDetails from "./show.vue";
import { breadCrumbData } from "../../../../../helpers/breadcrumbs/breadCrumbs";
import { setUserAction } from "../../../../../helpers/userActions/userActions";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Detail player Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, ShowDetails, downloadexcel },
  data() {
    return {
      fileName: "Detalles jugadores.xls",
      json_fields: {
        ronda: "roundId",
        cliente: "playerId.operator.client.name",
        operador: "playerId.operator.name",
        "Usuario ID": "playerId.userId",
        Usuario: "playerId.username",
        "Casino ID": "playerId.casinoId",
        "Tipo de transacción": "type",
        moneda: "playerId.currency",
        balance: "playerId.lastBalance",
        "Transaccion ID": "transactionId",
        ip: "geolocation.geolocation.ip",
        pais: "geolocation.geolocation.countryName",
        "usuario agente": "geolocation.userAgent",
        fecha: "createdAt",
      },
      tableData: [],

      title: "Detalles de Jugadores",
      items: breadCrumbData.reportPlayersDetails,
      loading: false,
      filterData: {},
      dataDetails: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      players: [],
      operators: [],
      roulettes: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "roundId",
          sortable: true,
          label: "Round ID",
        },
        {
          key: "playerId.operator.client.name",
          sortable: true,
          label: this.$t('tableReports.client'),
        },
        {
          key: "playerId.operator.name",
          sortable: true,
          label: this.$t('tableReports.operator'),
        },
        {
          key: "playerId.userId",
          sortable: true,
          label: this.$t('tableReports.userId'),
        },
        {
          key: "playerId.username",
          sortable: true,
          label: this.$t('betDetail.user'),
        },
        {
          key: "playerId.casinoId",
          sortable: true,
          label: "Casino ID",
        },
        {
          key: "type",
          sortable: true,
          label: this.$t('tableReports.transactionType'),
        },
        {
          key: "debit",
          sortable: true,
          label: this.$t('tableReports.debit'),
        },
        {
          key: "playerId.casinoId",
          sortable: true,
          label: this.$t('tableReports.credit'),
        },
        {
          key: "playerId.currency",
          sortable: true,
          label: this.$t('tableReports.currency'),
        },
        {
          key: "playerId.lastBalance",
          sortable: true,
          label: this.$t('tableReports.balance'),
        },
        {
          key: "transactionId",
          sortable: true,
          label: this.$t('tableReports.transactionId'),
        },
        {
          key: "details",
          sortable: true,
          label: this.$t('tableReports.details'),
        },
        {
          key: "createdAt",
          sortable: true,
          label: this.$t('tableReports.date'),
          class: "space-nowrap",
        },
        {
          key: "geolocation.geolocation.ip",
          sortable: true,
          label: "IP",
        },
        {
          key: "geolocation.geolocation.countryName",
          sortable: true,
          label: this.$t('tableReports.country'),
          class: "space-nowrap",
        },
        {
          key: "geolocation.userAgent",
          sortable: true,
          label: "User Agent",
          class: "space-nowrap",
        },
      ],

      typeform: {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      },
      clientFilter: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
    this.getPlayers();
    this.getOperator();
    this.getRoulettes();
  },
  methods: {
    getPlayers() {
      this.$http
        .get("/player-round/getPlayer")
        .then((response) => {
          this.players = response.data.res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOperator() {
      this.$http
        .get("/operators")
        .then((response) => {
          this.operators = response.data.operators;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filtrar() {
      this.loading = true;

      this.$http
        .get(`/reports/players/detail`, {
          params: this.filterData,
        })
        .then((response) => {
          this.tableData = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelFilter() {
      this.filterData = {};
      this.getData();
    },
    getRoulettes() {
      this.$http
        .get("/roulettes")
        .then((response) => {
          this.roulettes = response.data.roulettes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.loading = true;
      this.$http
        .get(`/reports/players/detail`)
        .then((response) => {
          this.tableData = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async exportData() {
      const response = await this.$http.get("/reports/players/detail", {
        params: this.filterData,
      });
      if (response.data != "") {
        let schema = setUserAction(
          this.$route.name,
          "",
          "Exportar los detalles de los jugadores"
        );
        this.$store.commit("actions/setActionsMut", schema);
        return response.data;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "danger",
          title: "Advertencia, no se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      };
      this.getData();
    },
    openDetails(item) {
      this.dataDetails = item;
      this.$refs.showDetails.modal = true;
    },
  },
  middleware: "authentication",
};
</script>


<style>
.space-nowrap {
  white-space: nowrap !important;
}
</style>
